@import "./../../Style/base";
@import "./../../Style/media";

.wrapper {
}

button {
    background-color: transparent;
    cursor: pointer;
    border: none;
}

.logout_btn {
    border: none;
    background-color: transparent;
    overflow: hidden;

    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 18px;
    color: #282a35;

    & > div {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    &:hover {
        color: #059963;
    }

    @include respond(small) {
        font-family: Source Sans Pro;
        font-style: normal;
        font-weight: normal;
        font-size: 20px;
        line-height: 25px;
        padding: 0;
    }
}
