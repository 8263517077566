body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

*:focus {
  outline: #04aa6d solid 2px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.close-button {
  background-color: transparent;
  cursor: pointer;
  border: none;
}

.logout_btn {
  border: none;
  background-color: transparent;
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  color: #282a35;
}

.logout_btn:hover {
  color: #059963;
}

.grecaptcha-badge {
  bottom: 100px !important;
  z-index: 1001;
}

.suspense-spinner {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100vw;
  height: 100vh;
  z-index: 1500;

  display: flex;
  align-items: center;
  justify-content: center;
}

.loader-spinner {
  width: 10rem !important;
  height: 10rem;
}
