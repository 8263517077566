@import '../../../utils/styles/_colors.scss';

.reset_password_form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  row-gap: 20px;
  padding-top: 20px;
}

.text {
  font-family: 'Source Sans Pro';
  color: $dark;
}

.reset_password_header {
  font-weight: 600;
  font-size: 24px;
  text-align: start;
}

.reset_password_text {
  font-weight: 400;
  font-size: 15px;
}

.password_form {
  display: flex;
  flex-direction: column;
  gap: 3px;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
}

.password_label {
  font-weight: 600;
  font-size: 17px;
}

.password_input {
  border: none;
  height: 40px;
  border: 1px solid #d4d1d3;
  border-radius: 7px;
  padding: 0px 10px 0px;
  width: 100%;
  border-radius: 7px;
  flex-grow: 1;
}

.reset_password_buttons {
  padding-top: 20px;
  display: flex;
  justify-content: space-between;
  gap: 3px;
  width: 100%;
}

.login_button {
  margin-top: 30px;
  flex-grow: 1;
  width: 100%;

  @media screen and (max-width: 970px) {
    font-size: 13px;
  }
}

.reset_password_button {
  flex-grow: 1;
  width: 100%;

  @media screen and (max-width: 970px) {
    font-size: 13px;
  }
}

.reset_pasword_done {
  display: flex;
  flex-direction: column;
  gap: 10px;
  justify-content: flex-start;
  align-items: flex-start;
}

.reset_done_message {
  font-weight: normal;
  font-size: 13px;
  line-height: 19px;
  color: #6c757d;
}

.validationHelper {
  column-count: 2;
  display: block;
  margin: 14px 0 0;
  list-style: none;
  padding-left: 0;

  @media screen and (max-width: 970px) {
    column-count: 1;
  }
}

.validationHelper li {
  font-weight: 600;
  font-size: 12px;
  color: #282a35;
  position: relative;
  padding-left: 20px;

  @media screen and (max-width: 970px) {
    font-size: 14px;
  }
}

.validationHelper li::before {
  content: '';
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  height: 6px;
  width: 6px;
  background-color: green;
  border-radius: 50%;
}

.validationHelper li.satisfied::before {
  background-color: gray;
}

.validationHelper li.satisfied {
  color: gray;
  font-weight: normal;
}

.passwordError {
  color: red;
  font-weight: normal;
  font-size: 14px;
  margin-top: 5px;
}

.password_container {
  width: 100%;
  position: relative;
}

.password_input_group {
  display: flex;
  align-items: center;
}

.password_toggle_icon {
  position: absolute;
  right: 10px;
  cursor: pointer;
  font-size: 16px;
}

.reset_password_text {}