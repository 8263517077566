.icon {
  margin-right: 8px;
}

.text {
  flex-grow: 1;
}

.animate_out {
  animation: animateOut 500ms;
}

.animate_in {
  animation: animateIn 500ms;
}

.close_btn {
  margin-left: auto;
  width: fit-content;
  height: fit-content;
  padding: 0px;

  &:hover > svg > path {
    fill: black;
  }
}

@keyframes animateOut {
  0% {
    right: 0px;
    opacity: 1;
  }
  100% {
    right: -400px;
    opacity: 0;
  }
}

@keyframes animateIn {
  0% {
    right: -400px;
    opacity: 0;
  }
  100% {
    right: 0px;
    opacity: 1;
  }
}

:global {
  .notification-toast-wrapper {
    &.toast {
      background: #ffffff;
      border: 1px solid #e3e3e3;
      box-sizing: border-box;
      box-shadow: 0px 16px 48px rgba(0, 0, 0, 0.175);
      border-radius: 16px;
      padding: 18px 21px;
      opacity: 1;
      position: relative;
      right: 0px;

      > .toast-header {
        display: flex;
        align-items: center;
        padding: 0.25rem 0;
        color: #282a35;
        font-family: Source Sans Pro;
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
        line-height: 24px;
        letter-spacing: 0px;
        text-align: left;
        background: none;
        border: 0px;
        border-top-left-radius: calc(0.25rem - 1px);
        border-top-right-radius: calc(0.25rem - 1px);
      }
      .toast-body {
        overflow: hidden;
        font-family: Source Sans Pro;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 0px;
        text-align: left;
        color: #282a35;
        padding: 0;

        .w3s-notif-upload-success-text {
          max-width: 280px;
        }
      }
    }
  }
}
