$success-color: #28a745;
$warning-color: #ffc107;
$danger-color: #dc3545;
$info-color: #17a2b8;
$animation-time: 0.5s;

.toast {
  position: fixed;
  top: 5px;
  right: 5px;
  min-width: 250px;
  border-left: 4px solid;
  animation: slideInRight ease $animation-time;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem;
  background-color: #202e35;
  color: #ffffff;
  z-index: 10;

  &-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 16px;
  }

  &-body {
    font-size: 14px;
  }

  &.success {
    border-color: $success-color;
  }
  &.warning {
    border-color: $warning-color;
  }
  &.danger {
    border-color: $danger-color;
  }
  &.info {
    border-color: $info-color;
  }

  .close {
    background: transparent;
    border: none;
    font-size: 21px;
    cursor: pointer;
    color: #ffffff;
  }
}

@keyframes slideInRight {
  from {
    opacity: 0;
    transform: translate3d(100%, 0, 0);
  }
  to {
    opacity: 1;
    transform: none;
  }
}
