@import "./../../Style/base";
@import "./../../Style/modal";

.celebration_wrapper {
    width: 100%;
    text-align: center;
    padding: 12px;
    svg {
        vertical-align: baseline;
    }
}

.cta_bottom_box {
    height: 80px;
}
