@import '../../../utils/styles/_colors.scss';

.signup_form_container {
  display: flex;
  flex-direction: column;
}

.signup_form {
  display: grid;
  grid-template-rows: auto auto 40px auto;
}

.signup_inputs {
  display: flex;
  flex-direction: column;
  gap: 3px;
}

.signup_input {
  border: none;
  height: 40px;
  border: 1px solid #d4d1d3;
  border-radius: 7px;
  padding: 0px 10px 0px;
}

.signup_reset_password_buttons {
  display: flex;
  justify-content: space-between;
  gap: 5px;
}

.signup_reset_password_button {
  flex-grow: 1;
}

.text {
  font-family: 'Source Sans Pro';
  font-weight: 600;
  color: $dark;
}

.term_text {
  padding-top: 10px;
  text-align: center;

  .agree_text {
    color: rgba(40, 42, 53, 0.5);
  }
}

.email_consent_checkbox {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  padding-top: 10px;

  accent-color: $primary-green;
}

.error_text {
  font-family: 'Source Sans Pro';
  font-weight: 500;
  color: red;
  padding-left: 10px;
  font-size: 12px;
  padding-top: 10px;
  padding-bottom: 10px;
}

.signup_buttons {
  display: flex;
  justify-content: space-between;
  gap: 5px;
}

.signup_button {
  flex-grow: 1;

  @media screen and (max-width: 787px) {
    font-size: 14px;
  }
}
