@mixin respond($breakpoint) {
    @if ($breakpoint == medium) {
        // bootstrap4 lg size
        @media (max-width: 991.98px) {
            @content;
        }
    }
    @if ($breakpoint == small) {
        @media (max-width: 800px) {
            @content;
        }
    }
}
