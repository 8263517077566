:root {
  --w3s-top-nav-bar-height: 88px; // updated from js
}

.top-nav-bar-placeholder {
  width: 100%;
  height: 88px;
  min-height: 88px;

  &.display-banner {
    height: 143px;
  }
}

.top-nav-bar {
  position: fixed;
  z-index: 999999;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 88px;
  border: none;
  overflow: hidden;

  &.display-banner {
    top: 54px;
  }
}

.top-nav-bar-banner {
  background: #282a35;
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  min-height: 54px;
  z-index: 173;
}

.top-nav-bar-banner-inner {
  min-height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  flex-wrap: nowrap;

  &.black {
    background: #282a35;
  }

  &.green {
    background: #d9eee1;
  }

  &.pink {
    background: #ffc0c7;
  }
}

.top-nav-bar-banner-badge-new {
  width: fit-content;
  background-color: #fff4a3;
  border-radius: 14px;
  padding-left: 24px;
  padding-top: 7px;
  padding-right: 24px;
  padding-bottom: 7px;
  font-family: Source Sans Pro;
  font-size: 10px;
  font-style: normal;
  font-weight: 700;
  line-height: 13px;
  text-align: center;
  margin-right: 10px;
}

.top-nav-bar-banner-text-new {
  color: #ffffff;
  font-family: Source Sans Pro;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  text-align: center;
}

.top-nav-bar-banner-link-new {
  color: pink;
  font-family: Source Sans Pro;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  text-align: center;
  text-decoration: none;

  &:hover {
    color: pink;
  }
}

.force-hide {
  display: none !important;
}