:global {
  .profile-settings-page-footer {
    z-index: 100;
  }
}

.terms_footer {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    text-align: center;
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 19px;
    color: rgba(40, 42, 53, 0.65);
    padding: 20px;
    box-sizing: border-box;
    a {
        display: inline;
        color: rgba(40, 42, 53, 0.65);
        text-decoration: underline;
        &:hover {
            color: #04aa6d;
        }
    }
}