$hover-color-bkg: #FAFAFA;
$primary-green: #04aa6d;
$color-disabled: #798488;
$disabled-bkg: #e2e8ea;
$hover-green: #059862;
$disabled-bkg: #e2e8ea;
$disabled-text: #798488;
$black: #282a35;

.toggle {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    gap: 7px;
    align-items: center;
}

.check {
    display: block;
    min-height: 1.5rem;
    padding-left: 1.5em;
    margin-bottom: 0.125rem;

    & .check_input {
        float: left;
        margin-left: -1.5em;
    }
}

.switch {
    padding-left: 2.5em;

    & .check_input {
        width: 2em;
        margin-left: -2.5em;
        background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3E%3Ccircle r='3' fill='rgba(0, 0, 0, 0.25)'/%3E%3C/svg%3E");
        background-position: 0;
        border-radius: 2em;
        transition: background-position .15s ease-in-out;
    }

    & .check_input:checked {
        background-position: 100%;
        background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3E%3Ccircle r='3' fill='%23fff'/%3E%3C/svg%3E");
    }
}

.switch_label {
    color: $black;

    &:hover:not(.disabled) {
        cursor: pointer;
    }
    &.disabled {
        color: $disabled-text;
    }
}

.check_input {
    width: 1em;
    height: 1em;
    margin-top: 0.35em;
    vertical-align: top;
    background-color: #fff;
    background-repeat: no-repeat;
    background-position: 50%;
    background-size: contain;
    border: 1px solid rgba(0,0,0,.25);
    -webkit-appearance: none;
    appearance: none;

    &:checked {
        background-color: $primary-green;
        border-color: $primary-green;
    }
    &:hover:not(:disabled) { 
        cursor: pointer;
    }
    &:hover:not(:checked):not(:disabled) {
        background-color: $hover-color-bkg;
    }
    &:hover:checked:not(:disabled) {
        background-color: $hover-green;
        border-color: $hover-green;
    }
    &:disabled {
        background-color: $disabled-bkg;
        border-color: $disabled-bkg;
    }
}
