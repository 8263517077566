@import '../../userlib_new/assets/styles/Colors.scss';
@import '../../userlib_new/assets/styles/Mixins.scss';

.wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.pic_wrapper {
  height: 265px;

  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: center;
  align-self: center;
  justify-content: flex-start;
  gap: 15px;

  margin-bottom: 50px;
}

.displayName {
  @include fadeIn();

  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
}

.nick {
  @include fadeIn();

  text-decoration: underline;
  cursor: pointer;

  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
}

.displayNick {
  @include fadeIn();

  font-style: normal;
  color: #4b85ff;
}

.displayNoNick {
  @include fadeIn();

  font-style: italic;
  color: $error-text-color;
}

.module_wrapper {
  width: 100%;
}

.password_wrapper {
  position: relative;
}

.reset_pwrd {
  position: absolute;
  right: 18px;
  top: 46px;

  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: $primary-green;
  cursor: default;

  &:hover:not(.disabled) {
    color: $hover-green;
    text-decoration: underline;
    cursor: pointer;
  }

  &.disabled {
    color: $disabled-text;
  }
}

.padding_top_0 {
  padding-top: 0px;
}

.action_wrapper {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;

  width: 100%;
}

.btn_width {
  width: 154px;
}

.profile_picture_no_nick {
  font-family: Source Sans Pro;
  font-size: 16px;
  font-weight: 600;
  font-style: normal;
  text-align: center;
  line-height: 26px;
  white-space: pre-line;

  cursor: default;

  display: flex;
  align-items: center;
  justify-content: center;

  width: 184px;
  height: 184px;

  color: white;
  background: #aaacb0;
  border-radius: 50%;
  border: 1px solid #e7e9eb;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
}
