.infobar {
    position: relative;
    margin: 0;
    background-color: #fff4a3;
    padding: 17px 49px;
    color: #282a35;
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 20px;
    a {
        color: #282a35;
        text-decoration: underline;
        &:hover {
            font-weight: bold;
        }
    }
}

.close_btn {
    position: absolute;
    top: 5px;
    right: -30px;
    height: auto;
    width: auto;
}
