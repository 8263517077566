@import '../../userlib_new/assets/styles/Colors.scss';

.wrapper {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  gap: 10px;

  padding: 40px 0px;
  position: relative;
}

.title {
  font-weight: 400;
  font-size: 30px;
  line-height: 38px;
}

.subtitle {
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: $disabled-text;
}

.header_wrapper {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  gap: 5px;
  align-items: flex-start;
}

.title_wrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  gap: 10px;
  align-items: center;
}
