$black: #282a35;
$white: #fff;

.button {
  margin: 0;
  font-family:
    Source Sans Pro,
    sans-serif;
  font-size: 18px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.17;
  letter-spacing: normal;
  text-align: center;
  white-space: nowrap;
  position: relative;
  cursor: pointer;
  height: 50px;
  padding-left: 40px;
  padding-right: 40px;
  border-radius: 25px;
  border: 0;
  box-sizing: border-box;
  display: inline-block;
  text-decoration: none;
  svg {
    vertical-align: baseline;
  }
}
