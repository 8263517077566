@import './../../SharedLib/Style/base';
@import './../../SharedLib/Style/modal';

.celebration_wrapper {
  width: 100%;
  text-align: center;
  padding: 12px;
  svg {
    vertical-align: baseline;
  }
}
