@import '../../utils/styles/_colors.scss';

.button {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 7px;
  font-family: 'Source Sans Pro';
  font-weight: 600;
  cursor: pointer;
}

.primary {
  background-color: $primary-green;
  color: white;
}

.secondary {
  background-color: white;
  color: #282a35;
  border: 1px solid #d4d1d3;
}

.disabledBtn {
  color: white;
  cursor: not-allowed;
}
