@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins/_breakpoints';
@import '../userlib_new/assets/styles/Colors.scss';

.wrapper {
  margin: 35px 25px;
  position: relative;

  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: flex-start;
  justify-content: center;
  gap: 10px;
}

.settings_wrapper {
  max-width: 767.98px;
  width: 100%;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-wrap: nowrap;
  gap: 130px;

  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  // color: $black;
}

.nav_wrapper {
  min-width: 170px;
  width: 170px;
  position: relative;
}

.navbar {
  position: fixed !important;
  top: 150px;
  left: 20px;
}

.top-nav-bar {
  z-index: 1390 !important;
}

.section_wrapper {
  width: 100%;
}

.profile {
  margin-bottom: 50px;
  width: 100%;
  padding-left: 40px;
  padding-right: 40px;
}

.notifications {
  position: fixed;
  top: 150px;
  right: 10px;
  width: 350px;
  z-index: 1000;
}

@include media-breakpoint-down(md) {
  /* .navbar {
        left: 5%;
    } */

  .wrapper {
    margin: 88px 15px;
  }

  .profile {
    padding-left: 10px;
    padding-right: 10px;
  }
}

@include media-breakpoint-between(xs, md) {
  .nav_wrapper {
    display: none;
  }
}

@include media-breakpoint-up(xl) {
  .navbar {
    left: 10%;
  }
}
