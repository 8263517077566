:root {
  --bs-spinner-width: 6rem;
  --bs-spinner-height: 6rem;
}

.container {
  display: grid;
  grid-template-columns: 60% 40%;
  grid-template-rows: 100%;
  grid-template-areas: 'left-panel right-panel';
  height: 100%;
  background-color: #282a35;

  @media (max-width: 768px) {
    grid-template-columns: 100%; /* Make it a single column layout */
    grid-template-rows: auto; /* Let rows be automatically sized */
    grid-template-areas:
      'right-panel'
      'left-panel'; /* Each panel takes a row */
  }
}

.left_panel {
  grid-area: left-panel;
}

.right_panel {
  grid-area: right-panel;
  border-radius: 8px;
}

.footer {
  grid-area: footer;
  height: 200px;
}

.loader_container {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 768px) {
    height: 100vh;
  }
}
