.footerContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 90px;
  background-color: #282a35; // Assuming a dark background
  color: #fff; // White text color
  background-image: url('./footer.png'); // Path to your background image
  background-size: cover;
  background-position: 65%;
  background-repeat: no-repeat;

  @media screen and (max-width: 1100px) {
    background-position: 75%;
  }

  @media screen and (max-width: 893px) {
    background-position: 65%;
  }

  @media screen and (max-width: 768px) {
    padding: 0;
    padding-left: 50px;
    padding-right: 50px;
    padding-top: 45px;
    padding-bottom: 100px;
    background-image: none;
    background-color: #282a35;
  }
}

.footerContent {
  display: grid;
  grid-template-columns: 60% 40%;
  width: 100%;
  align-items: center;
  justify-content: start;

  // @media screen and (max-width: 1800px) {
  //   grid-template-columns: 50% 50%;
  // }

  // @media screen and (max-width: 1170px) {
  //   grid-template-columns: 60% 40%;
  // }

  @media screen and (max-width: 942px) {
    grid-template-columns: 60% 40%;
  }

  @media screen and (max-width: 768px) {
    grid-template-columns: 100% 0%;
    justify-content: center;
  }
}

.leftColumn {
  display: flex;
  flex-direction: column;
  margin-left: -30px;

  @media screen and (max-width: 768px) {
    justify-content: center;
    align-items: center;
    margin-left: 0;
  }
}

.socialLinks {
  display: flex;
  gap: 10px; // Adjust the gap as needed
  margin-bottom: 20px; // Spacing between social links and the text

  a {
    color: #fff; // White color for icons
    font-size: 24px; // Adjust icon size as needed
    display: flex; // Aligns the FontAwesome icon properly
    align-items: center;
  }

  @media screen and (max-width: 768px) {
    margin-top: 45px;
  }
}

.brandLogo {
  margin-bottom: 50px; // Spacing between logo and social links
  img {
    height: auto; // Maintain the aspect ratio of the image
    width: 60px; // Adjust width as needed
    filter: brightness(0) invert(1); // Inverts the color to white
  }
}

.disclaimer {
  text-align: left;
  margin-bottom: 10px; // Spacing between disclaimer and copyright
  font-size: 12px; // Adjust font size as needed
  @media screen and (max-width: 768px) {
    text-align: center;
  }
}

.copyRight {
  text-align: left;
  width: 100%;
  margin-left: auto;
  margin-top: 8px;
  font-size: 12px; // Adjust font size as needed
  a {
    color: #fff; // White color for links
    text-decoration: underline; // Removes the underline from the link
  }
  a:hover {
    color: #fff4a3; // Adjust the color as needed
    text-decoration: underline; // Removes the underline from the link
  }
  @media screen and (max-width: 768px) {
    text-align: center;
  }
}

.footerImageMobile {
  display: none;
  @media screen and (max-width: 768px) {
    display: block;
    height: 350px;
    width: 100%;
    padding-left: 50px;
    padding-right: 50px;
    padding-top: 100px;
    background-image: url('./mobile_bg.svg');
    background-color: #282a35;
    // background-size: 200%;
    background-size: 85%;
    background-repeat: no-repeat;
    background-position: center;
  }

  @media screen and (max-width: 676px) {
    background-size: cover;
  }
}
