@import '../../../utils/styles/_colors.scss';

.reset_password_form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  row-gap: 20px;
  padding-top: 20px;
}

.text {
  font-family: 'Source Sans Pro';
  color: $dark;
}

.reset_password_header {
  font-weight: 600;
  font-size: 20px;
  text-align: start;
}

.reset_password_text {
  font-weight: 400;
  font-size: 15px;
}

.email_input {
  display: flex;
  flex-direction: column;
  gap: 3px;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
}

.reset_password_email_label {
  font-weight: 600;
  font-size: 17px;
}

.reset_password_email_input {
  border: none;
  height: 40px;
  border: 1px solid #d4d1d3;
  border-radius: 7px;
  padding: 0px 10px 0px;
  width: 100%;
  border-radius: 7px;
}

.reset_password_buttons {
  padding-top: 20px;
  display: flex;
  justify-content: space-between;
  gap: 3px;
  width: 100%;
}

.reset_password_button {
  flex-grow: 1;
  width: 100%;

  @media screen and (max-width: 970px) {
    font-size: 13px;
  }
}

.reset_pasword_done {
  display: flex;
  flex-direction: column;
  gap: 10px;
  justify-content: flex-start;
  align-items: flex-start;
}

.reset_done_message {
  font-weight: normal;
  font-size: 13px;
  line-height: 19px;
  color: #6c757d;
}
