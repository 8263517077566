@import "./../../Style/base";
@import "./../../Style/media";

$gapSize: 25px;
$green-color: #04aa6d;

.top-bar-menu-banner {
    background: #282a35;
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100%;
    min-height: 50px;
    z-index: 173;
}

.top-bar-menu-banner-inner {
    min-height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    flex-wrap: nowrap;

    &.black {
        background: #282a35;
    }

    &.green {
        background: #d9eee1;
    }

    &.pink {
        background: #ffc0c7;
    }
}

.new_button {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: flex-start;

    overflow: hidden;

    &:hover {
        overflow: visible;

        & a {
            color: $green-color;
            font-weight: regular;
        }
    }
}

.top-bar-menu-banner-badge-new {
    width: fit-content;
    background-color: #fff4a3;
    border-radius: 14px;
    padding-left: 24px;
    padding-top: 7px;
    padding-right: 24px;
    padding-bottom: 7px;
    font-family: Source Sans Pro;
    font-size: 10px;
    font-style: normal;
    font-weight: 700;
    line-height: 13px;
    text-align: center;
    margin-right: 10px;
}

.top-bar-menu-banner-text-new {
    color: #ffffff;
    font-family: Source Sans Pro;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    text-align: center;
}

.top-bar-menu-banner-link-new {
    color: pink;
    font-family: Source Sans Pro;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    text-align: center;
    text-decoration: none;

    &:hover {
        color: pink;
    }
}

.top-bar-menu-ph {
    position: relative;
    display: block;
    width: 100%;
    min-height: 73px;
}

.topbarmenu {
    min-height: 73px;
    background-color: #fff;
    padding-left: 40px;
    padding-right: 40px;
    border-bottom: 1px solid #e3e3e3;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;
    position: relative;
    width: 100%;
    box-sizing: border-box;

    &.fixed {
        position: fixed;
        top: 0px;
        left: 0;
        z-index: 1600;
    }

    &.fixed_logged_in {
        position: fixed;
        top: 50px;
        left: 0;
        z-index: 1600;
    }

    &.full_page {
        border-bottom: 0;
    }

    @include respond(medium) {
        padding-left: 16px;
    }

    nav {
        width: 100%;
        display: flex;
        align-items: center;
        padding-right: 15px;

        @include respond(medium) {
            height: 0;
            overflow: hidden;
            transition: height 0.3s ease-out;
            flex-direction: column;
            align-items: flex-start;
            position: absolute;
            top: 70px;
            left: 0;
            background-color: #fff;
            z-index: 173;
            padding-right: 0px;

            &.open {
                height: calc(100vh - 70px - 50px);
                overflow: scroll;
            }
        }
    }
}

.logo svg {
    margin-bottom: 2px;
}

.user_profile_button {
    display: inline-block;
    justify-self: flex-end;
    padding-bottom: 1px;

    & button {
        padding: 0px;
        width: 100%;
    }

    @include respond(medium) {
        display: block;
        padding: 0px 15px;
        position: relative;
        margin-left: 0;

        & button {
            font-family: Source Sans Pro;
            font-style: normal;
            font-weight: normal;
            font-size: 20px;
            line-height: 25px;
        }
    }
}

.button_wrapper {
    position: relative;

    & > .selctedLine {
        position: absolute;
        bottom: 4px;
        left: -1px;
        width: calc(100% + 2px);
        height: 2px;
        background: $green-color;
    }

    @include respond(medium) {
        & > .selctedLine {
            left: 14px;
            bottom: -2px;
            width: calc(100% - 13px);
            background: $green-color;
        }
    }
}

.topmenubutton {
    position: relative;

    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: #282a35;
    display: inline-block;
    //margin-left: 30px;
    text-decoration: none;

    &:hover {
        color: #282a35;
        text-decoration: none;
    }

    & > .selctedLine {
        position: absolute;
        bottom: -7px;
        left: -1px;
        width: calc(100% + 2px);
        height: 2px;
        background: $green-color;
    }

    @include respond(medium) {
        display: block;
        padding: 0px;
        padding-left: 16px;
        position: relative;
        font-family: Source Sans Pro;
        font-style: normal;
        font-weight: normal;
        font-size: 20px;
        line-height: 25px;
        margin-left: 0;

        & > .selctedLine {
            left: 14px;
            bottom: -2px;
            width: calc(100% - 13px);
            background: $green-color;
        }
    }
}

.menu_item_as_btn {
    @include respond(medium) {
        margin-left: 15px;
        padding: 0px !important;

        &:hover {
            text-decoration: none !important;
        }

        &:hover .btn_text {
            color: $green-color !important;
        }
    }
}

.button_as_no_frame {
    overflow: hidden;

    & .button_text {
        flex: 1;

        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        font-family: Source Sans Pro;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 18px;
        color: #282a35;

        &.btn_selected {
            font-weight: 600;
        }
    }

    &:hover {
        text-decoration: none !important;
        overflow: visible;
    }

    &:hover .button_text {
        color: $green-color !important;
        overflow: visible;
    }

    @include respond(medium) {
        & .button_text {
            font-family: Source Sans Pro;
            font-style: normal;
            font-weight: normal;
            font-size: 20px;
            line-height: 25px;
        }
    }
}

.btn_text {
    @include respond(medium) {
        font-family: Source Sans Pro;
        font-style: normal;
        font-weight: normal;
        font-size: 20px;
        line-height: 25px;
    }
}

.newicon {
    float: "right";
    margin-left: "8px";
}

.buttonbar {
    display: flex;
    align-items: center;
    width: 100%;

    a:hover {
        color: $green-color;
        font-weight: regular;
        overflow: visible;
    }

    nav {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-items: center;
        justify-content: space-between;
        gap: 15px;

        @include respond(medium) {
            flex-direction: column;
            align-items: flex-start;
            justify-content: flex-start;
            gap: $gapSize;
        }
    }
}

.selected {
    color: #282a35;
    font-weight: 600;
}

.start_wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: $gapSize;

    &:first-child {
        margin-left: 38px;
    }

    @include respond(medium) {
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        flex: none;

        &:first-child {
            margin-left: 0px;
            margin-top: 18px;
        }
    }
}

.end_wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    overflow: hidden;
    flex: 1;
    gap: $gapSize;

    & a {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    @include respond(medium) {
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        overflow: initial;
        padding-bottom: 20px;
    }
}
