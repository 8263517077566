.wrapper {
    overflow: hidden;
    background: rgba(40, 42, 53, 0.08);
}

.head {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 15px;
}

.snigel_row_text {
    position: relative;
    top: -8px;
    font-family: "Source Sans Pro";
    font-style: normal;
    font-weight: 300;
    font-size: 11px;
    line-height: 16px;

    & a {
        color: #282a35;
    }
}
