@import '../../../userlib_new/assets/styles/Colors.scss';

.wrapper {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 5px;
  width: fit-content;

  position: relative;
  cursor: pointer;
  user-select: none;

  &.disabled {
    cursor: default;
  }
}

/* Hide the browser's default checkbox */
.wrapper input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;

  &:disabled {
    cursor: default;
  }
}

/* Create a custom checkbox */
.wrapper > .checkmark {
  margin-top: 1px;
  border: 1px solid #767676;
  border-radius: 3px;
  background-color: white;
  opacity: 1;

  transition:
    background-color 0.4s ease,
    opacity 0.4s;
}

.wrapper.size_sm > .checkmark {
  height: 12px;
  width: 12px;
}

.wrapper.size_md > .checkmark {
  height: 15px;
  width: 15px;
}

.wrapper:hover:not(.disabled) input ~ .checkmark {
  border-color: #5d5c5c;
}

.wrapper:active:not(.disabled),
.wrapper:hover:not(.disabled) {
  outline: none;
}

/* On mouse-over, add a green background color */
.wrapper:focus:not(.disabled) input ~ .checkmark,
.wrapper:active:not(.disabled) input ~ .checkmark,
.wrapper:hover:not(.disabled) input ~ .checkmark {
  background-color: $hover-green;
  border-color: $hover-green;
  opacity: 0.7;
}

.wrapper.disabled input ~ .checkmark {
  background-color: $grey-bkg !important;
  border-color: $grey-bkg !important;
  opacity: 0.5;
}

/* When the checkbox is checked, add a green background */
.wrapper input:checked ~ .checkmark {
  background-color: $primary-green;
  border-color: $primary-green;
}

/* Create the checkmark/indicator (hidden when not checked) */
.wrapper > .checkmark:after {
  content: '';
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.wrapper input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.wrapper .checkmark:after {
  margin-left: 3px;
  border: solid white;
  border-width: 0 2px 2px 0;
  transform: rotate(40deg);
}

.wrapper.size_sm .checkmark:after {
  width: 4px;
  height: 8px;
}

.wrapper.size_md .checkmark:after {
  width: 6px;
  height: 10px;
}
