@import '../assets/styles/Colors.scss';
@import '../assets/styles/Vars.scss';

$border-width: 1px;
$width-reduction: 5px;

.wrapper {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  gap: 8px;
  justify-content: flex-start;

  & textarea {
    min-height: 100px;

    &:focus:not(.error):not(:disabled):not(.disabled),
    &:active:not(.error):not(:disabled):not(.disabled) {
      outline: none;
      border: $border-width solid $pressed-green;

      &.search + .searchIcon > path {
        fill: $black;
      }
    }

    &:hover:not(.error):not(:disabled):not(.disabled) {
      outline: none;
      border: $border-width solid $hover-green;

      &.search + .searchIcon > path {
        fill: $black;
      }
    }
  }

  & input {
    min-height: 46px;

    &:focus:not(.error):not(:disabled):not(.disabled),
    &:active:not(.error):not(:disabled):not(.disabled) {
      outline: none;
      border: $border-width solid $pressed-green;

      &.search + .searchIcon > path {
        fill: $black;
      }
    }

    &:hover:not(.error):not(:disabled):not(.disabled) {
      outline: none;
      border: $border-width solid $hover-green;

      &.search + .searchIcon > path {
        fill: $black;
      }
    }
  }
}

.title {
  width: calc(100% - 5px);
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
}

.item {
  width: calc(100% - 10px);
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  border: $border-width solid $border-color;
  border-radius: $border-radius-boxes-small;
  padding-left: 10px;
  padding-right: 10px;

  color: $black;

  &.disabled {
    cursor: default;
    color: $disabled-text;
  }

  &.error {
    outline: none;
    border: $border-width solid $error-border-color;
  }
}

.error_txt {
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: $error-text-color;
}

.info_txt {
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: $black;
  background-color: #fff4a3;
  padding: 5px;
}

.input_wrapper {
  width: 100%;
  position: relative;
}

.searchIcon,
.infocheck,
.check {
  position: absolute;
  right: 15px;
  width: 20px;
}

.check {
  top: 21px;
}

.infocheck {
  top: 15px;
}

.searchIcon {
  top: 12px;

  & > path {
    fill: $border-color;
  }
}
