.login_panel {
  display: grid;
  grid-template-columns: minmax(0, 1fr) minmax(0, 450px);
  grid-template-rows: 7%;
  grid-template-areas:
    'header header header'
    '. main .';
  width: 95%;

  @media screen and (max-width: 768px) {
    width: 100%;
    display: block;
  }
}

.form_header {
  padding: 0;
  width: fit-content;
  position: absolute;
  right: 20px;
  top: 20px;

  a {
    color: #000;
  }

  @media screen and (max-width: 768px) {
    display: none;
  }
}

.form_header_mobile {
  display: none;

  @media screen and (max-width: 768px) {
    display: flex;
    justify-content: space-between;
    margin-bottom: 16px;

    a {
      color: #000;
    }
  }
}

.login_signup {
  position: relative;
  grid-area: main;
  background-color: white;
  height: fit-content;
  padding-top: 3rem;
  padding-bottom: 5rem;
  padding-left: 3.5rem;
  padding-right: 3.5rem;
  border-radius: 8px;

  @media screen and (max-width: 942px) {
    padding-top: 2rem;
    padding-bottom: 4rem;
    padding-left: 1rem;
    padding-right: 1rem;
  }

  @media screen and (max-width: 768px) {
    border-radius: 0;
  }
}

.heading {
  grid-area: heading;
}

.header_login_text {
  align-items: flex-start;
  text-align: end;
  font-family: 'Source Sans Pro';
  font-size: 15px;
  font-weight: 600;
  grid-area: header_login_text;
  margin-top: 2rem;

  .login {
    color: #34a853;
    cursor: pointer;
    user-select: none;
  }
}

.social_login_container {
  grid-area: social_login_container;
  margin-top: 2rem;
}

.social_login_buttons {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, 1fr);
  row-gap: 5px;
  column-gap: 5px;
}

.or_text {
  width: 100%;
  opacity: 0.5;
  margin-top: 1rem;
  text-align: center;
}

.social_login_button {
  display: grid;
  grid-template-columns: 2fr 1fr;
  border: 1px solid #d4d1d3;
  border-radius: 7px;
  height: 40px;

  .provider_text {
    display: flex;
    align-items: center;
    padding-left: 15px;
    text-align: start;
    font-family: 'Source Sans Pro';
    font-weight: 600;
    height: 100%;
  }

  .provider_logo {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
  }
}

.content {
  margin-top: 1rem;
  grid-area: content;
  margin-right: 0.5rem;
}

.reset_password {
  grid-area: main;
}

.reset {
  grid-area: main;
}

.verification_pending {
  grid-area: main;
}

.popover_text {
  font-size: 12px;
  padding: 10px;
  font-family: 'Source Sans Pro';
}
