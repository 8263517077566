@import "./../../Style/base";
@import "./../../Style/media";

.buttonbar {
    nav {
        @media (min-width: 800px) and (max-width: 991.98px) {
            width: auto;
            height: auto;
            position: inherit;

            & > div:first-child {
                padding-bottom: 0px;
            }
        }
    }

    @media (min-width: 800px) and (max-width: 991.98px) {
        justify-content: space-between;
    }
}
