:global {
    .ProfileSettings {
        padding: 80px 25px 25px;

        > .-iwrp {
            // inner wrapper / inner container
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;

            > .-pane {
                font-family: "Source Sans Pro", sans-serif;
                width: 864px;
                max-width: 100%;
                background-color: #fff;
                border: 1px solid #e3e3e3;
                border-radius: 16px;
                box-sizing: border-box;
                padding: 25px 30px;
                margin-bottom: 20px;

                &:last-child {
                    margin-bottom: 0;
                }

                > .-title_wrp {
                    > .-title {
                        margin: 0;
                        padding: 0;
                        font-size: 24px;
                        line-height: 32px;
                        font-weight: 600;
                    }
                }

                > .-info_wrp {
                    p {
                        font-size: 16px;
                        line-height: 24px;
                        font-weight: 400;
                    }
                }

                > .-body_wrp {
                    padding-top: 5px;
                }
            }
        }

        .-fif_wrp {
            padding-bottom: 5px;

            &:last-child {
                padding-bottom: 0;
            }

            .-fif_label_wrp {
                label {
                    margin-top: 0;
                }
            }

            .-fif_input_wrp {
                width: 384px;
                max-width: 100%;

                input {
                    margin: 8px 0;
                }
            }
        }
    }
}
