@import "./../../../Style/base";
@import "./../../../Style/form";

.input_wrapper {
    div {
        label {
            margin: 0;
        }
    }
}

.email_message {
    color: #d9212c;
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;

    color: #6c757d;
}
