@import '../../../utils/styles/colors';

.verify_email {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 20px;
}

.text {
  font-family: 'Source Sans Pro';
  font-weight: 400;
  color: $dark;
  opacity: 60%;
}

.resendBtn {
  height: 40px !important;
  min-width: 100px;
}

.verification_done {
  padding-top: 20px;
  display: grid;
  grid-template-rows: auto auto auto auto;
  row-gap: 20px;
}

.verification_done_header {
  font-family: 'Source Sans Pro';
  font-weight: 600;
  font-size: 25px;
  text-align: start;
}

.confetti_icon {
  display: flex;
  justify-content: center;
  align-items: center;
}
