@import "./../../Style/base";
@import "./../../Style/modal";

.modal_inner {
    text-align: center;
    position: relative;
}

.full_page {
    border: none;
}

$spinner-width: 12px;
$spinner-color: #04aa6d;

.spinner {
    display: inline-block;
    box-sizing: content-box;
    border-width: $spinner-width;
    border-style: solid;
    border-color: $spinner-color $spinner-color $spinner-color transparent;
    border-radius: 50%;
    width: 90px;
    height: 90px;
    animation: spin 1.6s linear infinite;
    position: relative;
    margin: 30px auto;

    &:before,
    &:after {
        content: "";
        width: $spinner-width;
        height: $spinner-width;
        border-radius: 50%;
        background: $spinner-color;
        position: absolute;
        left: 3px;
    }

    &:before {
        top: 3px;
    }

    &:after {
        bottom: 3px;
    }
}

@keyframes spin {
    100% {
        transform: rotate(360deg);
    }
}
