.academyBanner {

  width: 100%;
  height: 40px;
  background-image: url('./bg.svg');
  background-size: cover;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;

  a {
    color: white;
  }
}

@media (max-width: 600px) {
  .academyBanner {
    display: flex;
    flex-direction: column;
    height: auto;
    padding: 10px;
  }
}
