.container {
  max-width: 1200px;
  align-content: center;
  justify-content: center;
  display: grid;
  grid-template-columns: 50% 50%;
  grid-template-rows: calc(max(100vh, 660px)) auto;
  grid-template-areas: 'main main' 'footer footer';

  @media screen and (max-width: 768px) {
    display: block;
    max-width: 100%;
  }
}

.main {
  grid-area: main;
}

.footer {
  grid-area: footer;
}
