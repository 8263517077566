.leftSideContainer {
  height: 100%;
  background-color: #282a35;
  color: white; // Assuming the text is white
  padding: 0px 60px 60px 60px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start; // Aligns items to the left
  // background-image: url('./rocket.png'); // Path to your background image
  // background-size: 250px; // Adjust the size as needed
  // background-position: bottom right;
  // background-position: calc(100% - 100px) calc(100% - 50px);
  // background-repeat: no-repeat;

  // @media screen and (max-width: 1480px) {
  //   background-size: 150px;
  // }

  @media screen and (max-width: 768px) {
    justify-content: flex-start;
    align-items: center;
    padding: 0 15px;
    height: auto;
  }
}

.header_logo {
  margin-top: 100px;

  @media screen and (max-width: 768px) {
    display: none;
    margin-top: 0;
  }
}

.content {
  width: 100%;
  margin-top: 60px;
  background-image: url('./rocket.svg'); // Path to your background image
  background-size: 50%; // Adjust the size as needed
  background-position: 80% 70%;
  background-repeat: no-repeat;
  padding-bottom: 80px;
  padding-right: 80px;

  // @media screen and (max-width: 1100px) {
  //   background-position: 100% 70%;
  // }

  @media screen and (max-width: 768px) {
    background-image: none;
    padding: 0;
    margin-top: 50px;
  }
}

.title,
.academyTitle {
  margin-bottom: 0.5rem;
  font-size: calc(1vw + 1.5rem);

  @media screen and (max-width: 768px) {
    width: 100%;
    font-size: calc(1vw + 2rem);
    text-align: center;
  }

  .heart {
    color: #00c853; // Adjust the color to match the heart emoji color
  }
}

.academyTitle {
  font-size: calc(1vw + 1.2rem);

  @media screen and (max-width: 768px) {
    font-size: calc(1vw + 1.2rem);
  }

  @media screen and (min-width: 1200px) {
    font-size: 28px;
  }
}

span.w3schooler {
  display: block;
}

span.academy {
  display: block;
  // white-space: nowrap;
}

.subtitle {
  font-size: 1.5rem; // Adjust the font size as needed
  margin-bottom: 1rem;

  @media screen and (max-width: 768px) {
    font-size: 1.25rem;
    text-align: center;
  }
}

.featuresList {
  list-style: none; // Removes default list styling
  padding: 0;
  margin-top: 1rem;
  margin-bottom: 2rem;

  li {
    display: flex;
    align-items: center;
    font-size: 1rem; // Adjust the font size as needed
    margin-bottom: 0.5rem;

    .checkIconContainer {
      border-radius: 50%;
      width: 15px;
      height: 15px;
      background-color: white;
      margin-right: 0.5rem;
    }

    .checkIcon {
      margin-top: -13px;
      color: #00c853; // Adjust the color to match your checkmark color
    }

    @media screen and (max-width: 768px) {
      display: flex;
      /* Add flex display to the list */
      align-items: center;
      /* Center items horizontally */
      justify-content: center;
    }
  }
}
