@import '../../../utils/styles/_colors.scss';

.login_form_container {
  display: flex;
  flex-direction: column;
}

.login_form {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.login_inputs {
  display: flex;
  flex-direction: column;
  gap: 3px;
}

.login_input {
  border: none;
  height: 40px;
  border: 1px solid #d4d1d3;
  border-radius: 7px;
  padding: 0px 10px 0px;
}

.login_reset_password_buttons {
  display: flex;
  justify-content: space-between;
  gap: 5px;
}

.login_reset_password_button {
  flex-grow: 1;

  @media screen and (max-width: 1160px) {
    font-size: 12px;
  }
}

.login_button {
  flex-grow: 1;
}

.error_text {
  font-family: 'Source Sans Pro';
  font-weight: 500;
  color: red;
  padding-left: 10px;
  font-size: 12px;
  padding-top: 10px;
  padding-bottom: 10px;
}
