.wrapper {
  width: 100%;
  display: block;

  > .iwrp {
    display: inline-block;
    border-radius: 4px;
    width: 100%;
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    color: #282a35;
    padding: 15px;
    position: relative;
    box-sizing: border-box;

    svg {
      position: relative;
      top: 3px;
      margin-right: 7px;
      vertical-align: baseline;
    }
  }
}

.success {
  background-color: #d9eee1;
}

.warning {
  background-color: #fff4a3;
}

.danger {
  background-color: #ffc0c7;
}

.info {
  background-color: #d9dfee;
}
