:global {
  .loader-spinner {
    font-size: 16px;
    width: 10em !important;
    height: 10em !important;
  }

  .spinner-border {
    font-size: 16px;
    --bs-spinner-width: 2em;
    --bs-spinner-height: 2em;
  }
}

.loader_container {
  background-color: #1a1b23;
  position: absolute;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  > div {
    opacity: 0.6;
  }

  @media (max-width: 768px) {
    height: 100vh;
  }
}
