@import "./../../Style/base";
@import "./../../Style/media";

.invalid {
    color: rgb(40, 42, 53);
    list-style-type: none;
}

.validation {
    font-size: 14px;
    font-family: Source Sans Pro;
    svg {
        position: relative;
        top: 2px;
        margin: 0 7px 2px 0;
        vertical-align: baseline;
    }
    @include respond(small) {
        font-size: 13px;
    }
}

.valid {
    color: rgba(40, 42, 53, 0.3);
    list-style-type: none;
}

.error {
    color: #D9212C;
    list-style-type: none;
}
