@import "./../../Style/base";

.password_is_valid {
    background: #d9eee1;
    border-radius: 4px;
    width: 100%;
    padding: 13px;
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    margin-top: 16px;
    color: #282a35;
}

.pwd_validation {
    margin: 14px 0 0;
    display: block;
    columns: 2;
}
