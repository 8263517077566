@import "./media";

.modal {
    display: none;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 478px;
    max-width: 100%;
    max-height: 100%;
    z-index: 150;
    border-radius: 4px;
    box-shadow: 0 0 15px 4px rgba(0, 0, 0, 0.06) inset, 0 0 15px 4px rgba(0, 0, 0, 0.06);
    &.full_page {
        box-shadow: none;
    }

    @include respond(small) {
        box-shadow: none;
        top: 75px;
        left: 0;
        transform: none;
        width: 100%;
    }
}

.modal_inner {
    position: relative;
    box-sizing: border-box;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: 48px 48px;
    background-color: #fff;
    border: 1px solid #e3e3e3;
    border-radius: 16px;
    text-align: left;
    font-family: Source Sans Pro;
    h1 {
        font-weight: bold;
        font-size: 29px;
        color: #282a35;
        margin: 0;
    }

    @include respond(small) {
        border: none;
        padding: 48px 29px;
    }
}

.close_button {
    position: absolute;
    top: 40px;
    right: 40px;
    cursor: pointer;

    &.full_page {
        display: none;
        top: 30px;
        right: 30px;
        @include respond(small) {
            display: block;
        }
    }
}

.show {
    display: block;
}

.input_wrapper {
    display: flex;
    flex-direction: column;

    div.label_wrapper {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: flex-end;
        label {
            font-family: Source Sans Pro;
            font-weight: 600;
            font-size: 16px;
            margin: 30px 0 0 0;
            display: inline-block;
        }

        span {
            font-size: 13px;
            svg {
                display: inline-block;
                margin-right: 4px;
                position: relative;
                top: 2px;
            }

            span {
                color: #04aa6d;
                font-weight: 600;
                display: inline-block;
                margin-left: 6px;
                cursor: pointer;
            }
        }
    }
}

.input_field {
    padding: 10px;
    margin: 6px 0;
    border-radius: 4px;
    border: 1px solid #ced4da;
    background-color: #fff;
    box-sizing: border-box;
    box-shadow: 0px 0px 0px 3.2px rgba(0, 123, 255, 0.25);
    &:focus {
        background-color: #fff;
        border: 1px solid #80bdff;
    }

    &.invalid {
        border: 1px solid #d9212c;
        box-shadow: 0px 0px 0px 3.2px rgba(217, 33, 44, 0.25);
        border-radius: 4px;
    }
}

.show_pwd_btn {
    cursor: pointer;
}

.pwd_validation {
    margin: 14px 0 0;
    display: block;
    columns: 2;
    svg {
        margin: 0 6px 2px 0;
    }
}

.password_is_valid {
    background: #d9eee1;
    border-radius: 4px;
    width: 100%;
    padding: 13px;
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    margin-top: 16px;
    color: #282a35;
}

.terms {
    font-family: Source Sans Pro;
    font-size: 13px;
    font-weight: 400;
    margin-top: 18px;
    a {
        font-weight: 700;
        color: #282a35;
        text-decoration: none;
        &:hover {
            color: #04aa6d;
        }
    }
}

.login_margin {
    height: 39px;
}

.error {
    background-color: #ffc0c7;
    border-radius: 4px;
    padding: 13px;
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 19px;
    color: #282a35;
    margin-top: 16px;
}

.email_error {
    color: #d9212c;
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
}

.cta_bottom_box {
    position: relative;
    top: 6px;
    width: 100%;
    box-sizing: border-box;
    @include respond(small) {
        width: 100%;
    }
}
