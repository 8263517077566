$green: #04aa6d;
$green-hover: #059862;

$dark: #282a35;
$dark-hover: black;

$disabled: #e7e7e7;
$color-disabled: #b9bfc1;

$purple: #9763f6;
$purple-hover: #7d53cc;

$red: #d9212c;
$red-hover: #c5212c;

$border-color: #dee2e6;
$border-color-hover: #a2a2a2;

$shadow-dark: #454651;
$shadow-purple: #9763f6;
$shadow-red: #ef2634;
$shadow-light: #e7e7e7;

@mixin setShadow($color: "dark") {
    @if ($color == "purple") {
        box-shadow: 0px 0px 4px $shadow-purple;
    } @else if ($color == "red") {
        box-shadow: 0px 0px 4px $shadow-red;
    } @else if ($color == "light") {
        box-shadow: 0px 0px 4px $shadow-light;
    } @else {
        box-shadow: 0px 0px 4px $shadow-dark;
    }
}

@mixin setRound() {
    position: relative;
    padding: 0px;
    max-width: 44px;
    min-width: 44px;
    width: 44px;
    height: 44px;
    border-radius: 50%;

    transition: max-width 0.7s ease;
}

@mixin setSmallRound() {
    max-width: 32px;
    min-width: 32px;
    width: 32px;
    height: 32px;
}

.button_wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 8px;

    border: 1px solid transparent;
    border-radius: 48px;
    padding: 10px 20px;
    color: $dark;

    &:disabled {
        cursor: default;
        color: $color-disabled;
        border-color: $color-disabled;
        background: $disabled;

        & > .icon path,
        & > .external_link_icon path,
        & > .stars_icon path {
            fill: $color-disabled;
        }
    }

    &.loading {
        pointer-events: none;
    }
}

.invisible {
    opacity: 0;
    pointer-events: none;
}

.icon {
    display: flex;
    align-items: center;
}

.text {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    white-space: nowrap;
}

.spinner {
    position: absolute;
    margin: auto;
}

.btn_green {
    background: $green;
    border-color: $green;
    color: white;

    &:hover:not(:disabled):not(.loading) {
        background: $green-hover;
        border-color: $green-hover;
    }

    &:not(:disabled) > .text {
        color: white;
    }

    & > .external_link_icon path,
    & > .stars_icon path {
        fill: white;
    }
}

.btn_noframe {
    background: transparent;
    border-color: transparent;
    color: $dark;
    padding-left: 0px;
    padding-right: 0px;

    &:disabled {
        border-color: transparent;
    }

    &:hover:not(:disabled):not(.loading) {
        color: $dark-hover;
        text-decoration: underline;
    }

    &:disabled {
        color: $color-disabled;
        background: transparent;

        & > .external_link_icon path,
        & > .stars_icon path {
            fill: $color-disabled;
        }
    }
}

.btn_red {
    background: $red;
    border-color: $red;

    &:hover:not(:disabled):not(.loading) {
        background: $red-hover;
        border-color: $red-hover;
    }

    &:not(:disabled) > .text {
        color: white;
    }

    & > .external_link_icon path,
    & > .stars_icon path {
        fill: white;
    }
}

.btn_purple {
    background: $purple;
    border-color: $purple;

    &:hover:not(:disabled):not(.loading) {
        background: $purple-hover;
        border-color: $purple-hover;
    }

    &:not(:disabled) > .text {
        color: white;
    }

    & > .external_link_icon path,
    & > .stars_icon path {
        fill: white;
    }
}

.btn_dark {
    background: $dark;
    border-color: $dark;

    &:hover:not(:disabled):not(.loading) {
        background: $dark-hover;
        border-color: $dark-hover;
    }

    &:not(:disabled) > .text {
        color: white;
    }
}

.btn_light {
    background: white;
    border-color: $border-color;

    &:hover:not(:disabled):not(.loading) {
        border-color: $border-color-hover;
        color: black;
    }
}

.shadow-dark {
    @include setShadow("dark");

    &:disabled {
        box-shadow: none;
    }
}

.shadow-purple {
    @include setShadow("purple");

    &:disabled {
        box-shadow: none;
    }
}

.shadow-red {
    @include setShadow("red");

    &:disabled {
        box-shadow: none;
    }
}

.shadow-light {
    @include setShadow("light");

    &:disabled {
        box-shadow: none;
    }
}

.round {
    @include setRound();

    &.small {
        @include setSmallRound();
    }

    & > .external_link_icon {
        display: none;
    }

    & > .stars_icon {
        position: absolute;
        top: 0px;
        right: 0px;
    }

    &.small > .stars_icon {
        top: -2px;
        right: -2px;
    }

    &:not(.small) > .stars_icon {
        scale: 1.6;
    }

    & > .text {
        display: none;
    }

    &.btn_red:not(:disabled) > .stars_icon path,
    &.btn_purple:not(:disabled) > .stars_icon path,
    &.btn_green:not(:disabled) > .stars_icon path {
        fill: black;
    }

    &.selected {
    }

    & .icon.hide_icon_on_small {
        display: none;
    }
}

@media (min-width: 991.99px) {
    .stars_icon_right.stars_icon_pos_right {
        display: block;
    }

    .stars_icon_right.stars_icon_pos_left {
        display: none;
    }

    .stars_icon_left.stars_icon_pos_left {
        display: block;
    }

    .stars_icon_left.stars_icon_pos_right {
        display: none;
    }
}

// bootstrap4 lg size
@media (max-width: 991.98px) {
    .auto_round {
        @include setRound();

        &.small {
            @include setSmallRound();
        }

        & > .external_link_icon {
            display: none;
        }

        & > .stars_icon {
            position: absolute;
            top: 0px;
            right: 0px;
        }

        &.small > .stars_icon {
            top: -2px;
            right: -2px;
        }

        &:not(.small) > .stars_icon {
            scale: 1.6;
        }

        & > .text {
            display: none;
        }

        &.btn_red:not(:disabled) > .stars_icon path,
        &.btn_purple:not(:disabled) > .stars_icon path,
        &.btn_green:not(:disabled) > .stars_icon path {
            fill: black;
        }

        & .icon.hide_icon_on_small {
            display: none;
        }
    }

    .stars_icon_mobil_left.stars_icon_right {
        display: none;
    }

    .stars_icon_mobil_left.stars_icon_left {
        display: block;
    }

    .stars_icon_mobil_right.stars_icon_left {
        display: none;
    }

    .stars_icon_mobil_right.stars_icon_right {
        display: block;
    }

    .btn_mobile_green {
        background: $green !important;
        border-color: $green !important;
        color: white !important;

        &:hover:not(:disabled):not(.loading) {
            background: $green-hover !important;
            border-color: $green-hover !important;
        }

        &:not(:disabled) > .text {
            color: white !important;
        }

        & > .external_link_icon path,
        & > .stars_icon path {
            fill: white !important;
        }
    }

    .btn_mobile_noframe {
        background: transparent !important;
        border-color: transparent !important;
        color: $dark !important;
        padding-left: 0px !important;
        padding-right: 0px !important;

        &:disabled {
            border-color: transparent !important;
        }

        &:not(:disabled) > .text {
            color: $dark !important;
        }

        &:hover:not(:disabled):not(.loading) {
            color: $dark-hover !important;
            text-decoration: underline;
        }

        &:disabled {
            color: $color-disabled !important;
            background: transparent !important;

            & > .external_link_icon path,
            & > .stars_icon path {
                fill: $color-disabled !important;
            }
        }

        & > .external_link_icon path,
        & > .stars_icon path {
            fill: #9763f6 !important;
        }
    }

    .btn_mobile_red {
        background: $red !important;
        border-color: $red !important;

        &:hover:not(:disabled):not(.loading) {
            background: $red-hover !important;
            border-color: $red-hover !important;
        }

        &:not(:disabled) > .text {
            color: white !important;
        }

        & > .external_link_icon path,
        & > .stars_icon path {
            fill: white !important;
        }
    }

    .btn_mobile_purple {
        background: $purple !important;
        border-color: $purple !important;

        &:hover:not(:disabled):not(.loading) {
            background: $purple-hover !important;
            border-color: $purple-hover !important;
        }

        &:not(:disabled) > .text {
            color: white !important;
        }

        & > .external_link_icon path,
        & > .stars_icon path {
            fill: white !important;
        }
    }

    .btn_mobile_dark {
        background: $dark !important;
        border-color: $dark !important;

        &:hover:not(:disabled):not(.loading) {
            background: $dark-hover !important;
            border-color: $dark-hover !important;
        }

        &:not(:disabled) > .text {
            color: white !important;
        }

        & > .external_link_icon path,
        & > .stars_icon path {
            fill: #9763f6 !important;
        }
    }

    .btn_mobile_light {
        background: white !important;
        border-color: $border-color !important;

        &:hover:not(:disabled):not(.loading) {
            border-color: $border-color-hover !important;
            color: black !important;
        }

        &:not(:disabled) > .text {
            color: $dark !important;
        }

        & > .external_link_icon path,
        & > .stars_icon path {
            fill: #9763f6 !important;
        }
    }
}
