@import '../../userlib_new/assets/styles/Colors.scss';

$wrapperGap: 10px;
$itemHeight: 35px;

.wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  flex-wrap: nowrap;
  gap: $wrapperGap;
}

.item {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 40px;

  cursor: pointer;

  &.selected {
    font-weight: 600;
  }
}

.spinner,
.error_icon {
  margin-left: 5px;
  width: 20px;
  height: 20px;
  padding-bottom: 3px;
}

.marker {
  height: 3px;
  width: 100%; // calc(100% + 10px);
  background-color: $primary-green;
  z-index: -1;

  position: absolute;
  left: 0px; //-5px;
  transition: top 0.5s ease;

  &.first {
    top: $itemHeight - 4px;
  }

  &.second {
    top: ((2 * $itemHeight) + $wrapperGap);
  }

  &.hover_first {
    top: $itemHeight - 4px !important;
  }

  &.hover_second {
    top: ((2 * $itemHeight) + $wrapperGap) !important;
  }
}

.item_wrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  gap: 10px;
}
