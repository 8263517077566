.input_field {
    padding: 14px;
    margin: 6px 0;
    border-radius: 4px;
    border: 1px solid #ced4da;
    background-color: #fff;
    box-sizing: border-box;
    &:focus {
        box-shadow: 0px 0px 0px 3.2px rgba(0, 123, 255, 0.25);
        background-color: #fff;
        border: 1px solid #80bdff;
        outline: none;
    }

    &.invalid {
        border: 1px solid #d9212c;
        box-shadow: 0px 0px 0px 3.2px rgba(217, 33, 44, 0.25);
        border-radius: 4px;
    }
}

input[type="text"],
input[type="email"],
input[type="password"] {
    font-family: Source Sans Pro;
    font-size: 14px;
}

.input_wrapper {
    display: flex;
    font-family: Source Sans Pro;
    flex-direction: column;

    div.label_wrapper {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: flex-end;
        label {
            font-family: Source Sans Pro;
            font-weight: 600;
            font-size: 16px;
            margin: 30px 0 0 0;
            display: inline-block;
        }

        span {
            font-size: 14px;
            svg {
                display: inline-block;
                margin-right: 4px;
                position: relative;
                top: 2px;
            }

            span {
                color: #04aa6d;
                font-weight: 600;
                display: inline-block;
                margin-left: 6px;
                cursor: pointer;
            }
        }
    }
}
