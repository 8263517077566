$desktop-breakpoint: 1200px;
$tablet-breakpoint: 767px;

@function calcRem($base-size, $element-size, $base-percentage: 10) {
  // base percentage is same as font-size of html
  $rem-val: $base-size / $base-percentage;
  $ratio: $element-size / $rem-val;
  @return $ratio * 1rem;
}

@mixin setDynamicSize($breakpoint, $property, $value) {
  #{$property}: $value;

  @media screen and (max-width: $breakpoint) {
    #{$property}: calcRem($breakpoint, $value);
  }
}

@mixin setDynamicSizes($breakpoint, $entries...) {
  @each $property, $value in $entries {
    #{$property}: $value;
  }

  @media screen and (max-width: $breakpoint) {
    @each $property, $value in $entries {
      #{$property}: calcRem($breakpoint, $value);
    }
  }
}

:global {
  html {
    &.w3s-classroom-invitation-page {
      font-size: 10vw; // used for dynamic rem sizing

      #root {
        display: flex;
        flex-flow: column;
        height: 100vh;
        font-size: 16px;
        line-height: 20px;
      }
    }
  }

  .w3s-classrooms-invitation {
    flex: 1;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: stretch;

    .-inline-link {
      color: #04aa6d !important;
    }

    ol,
    ul {
      padding-left: 2em;
      margin-bottom: 0;
      > li {
        margin-bottom: 0.5em;
      }
    }

    > .-banner {
      position: relative;
      flex: 1;
      height: 100%;
      display: flex;
      // align-items: center;
      justify-content: center;

      > .-background {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        overflow: hidden;
        z-index: -1;

        > svg {
          width: 100%;
          height: 100%;
        }
      }

      > .-info {
        text-align: center;
        position: relative;
        @include setDynamicSizes(
          $desktop-breakpoint,
          ('padding', 15px),
          ('margin-top', 200px)
        );

        @media screen and (max-width: $tablet-breakpoint) {
          padding: 10px;
          margin-top: 0;
        }

        $info-tablet-ratio: 0.7;

        > .-logo {
          display: inline-block;
          @include setDynamicSizes(
            $desktop-breakpoint,
            ('width', 90px),
            ('padding-top', 15px)
          );

          @media screen and (max-width: $tablet-breakpoint) {
            width: 90px * $info-tablet-ratio;
            padding-top: 15px;
          }

          > svg {
            width: 100%;
            height: auto;
          }
        }

        > .-title {
          color: #ffffff;
          font-weight: bold;
          max-width: 100%;
          display: block;
          margin: 0 auto;
          @include setDynamicSizes(
            $desktop-breakpoint,
            ('font-size', 50px),
            ('line-height', 58px),
            ('width', 320px),
            ('padding-top', 20px),
            ('padding-bottom', 20px)
          );

          @media screen and (max-width: $tablet-breakpoint) {
            font-size: 50px * $info-tablet-ratio;
            line-height: 58px * $info-tablet-ratio;
            width: 320px * $info-tablet-ratio;
            padding-top: 20px * $info-tablet-ratio;
            padding-bottom: 20px * $info-tablet-ratio;
          }
        }

        > .-subtitle {
          color: #f2f2f2;
          max-width: 100%;
          display: block;
          margin: 0 auto;
          @include setDynamicSizes(
            $desktop-breakpoint,
            ('font-size', 25px),
            ('line-height', 33px),
            ('width', 400px),
            ('padding-bottom', 35px)
          );

          @media screen and (max-width: $tablet-breakpoint) {
            // original size * $info-tablet-ratio
            font-size: 25px * $info-tablet-ratio;
            line-height: 33px * $info-tablet-ratio;
            width: 400px * $info-tablet-ratio;
            padding-bottom: 35px * $info-tablet-ratio;
          }
        }
      }
    }

    > .-body {
      background-color: #ffffff;
      width: 50%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      // min-width: 580px;
      @include setDynamicSize(980px, 'min-width', 580px);
      // padding: 50px 80px;

      @include setDynamicSizes(
        $desktop-breakpoint,
        // ('min-width', 580px),
        ('padding-top', 60px),
        ('padding-bottom', 60px),
        ('padding-left', 80px),
        ('padding-right', 80px)
      );
      // @media screen and (max-width: 1200px) {
      //   padding: 30px 50px;
      // }

      @media screen and (max-width: $tablet-breakpoint) {
        width: 100%;
        padding: 25px;
      }

      > .-inner-wrapper {
        position: relative;
        max-width: 700px;

        @media screen and (max-width: $tablet-breakpoint) {
          max-width: 100%;
        }

        > .-close-btn {
          position: fixed;
          margin-top: 88px;
          margin-top: var(--w3s-top-nav-bar-height);
          // top: 50px;
          // right: 50px;
          width: 40px;
          max-width: 40px;
          height: 40px;
          max-height: 40px;
          border-radius: 5px;
          background-color: #ffffff;
          border-color: #ffffff;
          color: #d0d3d6;
          padding: 10px 20px;
          font-size: 14px;
          line-height: 18px;
          cursor: pointer;
          -webkit-transition: none !important;
          -moz-transition: none !important;
          -o-transition: none !important;
          transition: none !important;
          box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1);

          @include setDynamicSizes(
            $desktop-breakpoint,
            ('top', 35px),
            ('right', 35px)
          );

          > svg {
            position: absolute;
            top: 50%;
            margin-top: -8px;
            left: 50%;
            margin-left: -8px;
            width: 16px;
            height: 16px;
            color: #d0d3d6;
          }

          &:hover {
            border-color: #666;
            color: #666;

            > svg {
              color: #666;
            }
          }
        }

        > .alert {
          font-size: 16px;
          line-height: 20px;
          --bs-alert-padding-x: 1em;
          --bs-alert-padding-y: 1em;
          --bs-alert-margin-bottom: 1em;
          --bs-alert-border-radius: 0.375em;
          border-radius: var(--bs-alert-border-radius);
          margin-bottom: var(--bs-alert-margin-bottom);
          padding: var(--bs-alert-padding-y) var(--bs-alert-padding-x);

          p {
            margin-bottom: 0;
          }
        }

        > .-pane {
          > .-title {
            color: #262262;
            font-weight: bold;
            font-size: 22px;
            line-height: 27px;
          }

          > .-description {
            padding-top: 20px;
            color: #222222;
            font-weight: normal;
            font-size: 14px;
            line-height: 19px;
          }

          > .-table {
            padding-top: 20px;
            width: 100%;

            > table {
              width: 100%;

              > thead {
                color: #999999;

                > tr {
                  & > th,
                  & > td {
                    padding: 10px 10px 10px 5px;
                    font-size: 14px;
                    line-height: 18px;
                  }
                }
              }

              > tbody {
                background-color: #f5f7fa;

                > tr {
                  & > th,
                  & > td {
                    padding: 15px 10px;
                    font-size: 14px;
                    line-height: 18px;
                  }
                }
              }
            }
          }

          &.-organization {
            margin-top: 50px;

            .-organization-logo {
              > svg,
              > img {
                display: inline-block;
                width: 40px;
                height: auto;
              }
            }
          }

          &.-classroom {
            margin-top: 50px;
          }

          &.-products,
          &.-classes {
            margin-top: 30px;

            > .-title {
              color: #999999;
              padding: 10px 10px 10px 5px;
              font-size: 14px;
              line-height: 18px;
            }

            > .-list {
              padding-top: 5px;

              > .-tag {
                display: inline-block;
                padding: 5px 10px;
                margin: 5px;
                border-radius: 9px;
                background-color: #f5f7fa;
                border: 1px solid #e0e0e0;
                color: #222222;
                font-size: 12px;
                line-height: 15px;

                &.-course,
                &.-course-program,
                &.-subscription {
                  background-color: #f2fcf5;
                  border: 1px solid #04aa6d;
                }

                &.-cert,
                &.-cert-program {
                  background-color: #f9f6fe;
                  border: 1px solid #8f65ee;
                }

                > .-logo {
                  display: inline-block;
                  width: 15px;
                  height: 15px;
                  min-height: 15px;
                  line-height: 15px;
                  margin-right: 5px;
                  overflow: hidden;
                  position: relative;
                  bottom: -1px;

                  > svg {
                    width: 100%;
                    height: auto;
                    color: #222222;
                    fill: currentColor;
                  }
                }

                > .-text {
                  display: inline-block;
                  min-height: 15px;
                  position: relative;
                  top: -1px;
                }
              }
            }
          }

          &.-take-action {
            margin-top: 50px;
            padding-bottom: 100px;

            > .-accept-invitation {
              > .-terms {
                position: relative;
                display: flex;
                padding-left: 30px;

                > .-checkbox-wrapper {
                  position: absolute;
                  top: 0;
                  left: 0;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  flex: 1;
                  height: 100%;

                  > .-checkbox {
                    accent-color: #04aa6d;
                    width: 20px;
                    height: 20px;
                    cursor: pointer;
                  }
                }

                > .-text {
                  font-size: 13px;
                  line-height: 17px;
                }
              }
            }

            .-buttons {
              display: flex;
              justify-content: flex-end;
              margin-top: 50px;

              @media screen and (max-width: 600px) {
                justify-content: center;
                flex-direction: column;
                align-items: center;
              }

              > button {
                padding: 10px 20px;
                border-radius: 5px;
                font-size: 14px;
                line-height: 18px;
                cursor: pointer;
                margin: 5px;
                -webkit-transition: none !important;
                -moz-transition: none !important;
                -o-transition: none !important;
                transition: none !important;

                @media screen and (max-width: 600px) {
                  width: 100%;
                  max-width: 300px;
                }

                &.-accept,
                &.-continue {
                  background-color: #04aa6d;
                  color: #ffffff;
                  margin-left: 10px;

                  &:hover {
                    background-color: darken(#04aa6d, 5%);
                  }
                }

                &.-decline {
                  background-color: #ffffff;
                  color: #222222;
                  margin-left: 10px;

                  &:hover {
                    background-color: #f5f5f5;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  @media screen and (max-width: $tablet-breakpoint) {
    .w3s-classrooms-invitation {
      flex-direction: column;

      > .-banner {
        width: 100%;
        height: auto;
      }

      > .-body {
        width: 100%;
        min-width: unset;
      }
    }
  }
}
