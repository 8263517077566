.building {
  width: 36px;
  height: 36px;
}

.building.small {
  width: 22px;
  height: 22px;
}

.check_dark > svg {
  fill: white;
}
