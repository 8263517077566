@import "./../../Style/base";
@import "./../../Style/media";

.TopBarMenu__main {
    position: relative;
    // background-color: pink;
}

.TopBarMenu__placeholder {
    position: relative;
    display: block;
    width: 100%;
    min-height: 73px;
}

.TopBarMenu__inner_wrp {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 170;
}

.TopBarMenu__inner_wrp__banner {
    position: relative;
    width: 100%;
    min-height: 50px;
    background: #282a35;
    display: flex;
    justify-content: center;
    align-items: center;
}

.TopBarMenu__inner_wrp__nav_menu {
    min-height: 73px;
    background-color: #fff;
    padding-left: 40px;
    padding-right: 40px;
    border-bottom: 1px solid #e3e3e3;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;
    position: relative;
    width: 100%;
    box-sizing: border-box;

    // &.fixed {
    //     position: fixed;
    //     top: 0px;
    //     left: 0;
    //     z-index: 160;
    // }

    // &.fixed_logged_in {
    //     position: fixed;
    //     top: 50px;
    //     left: 0;
    //     z-index: 160;
    // }

    &.full_page {
        border-bottom: 0;
    }

    @include respond(medium) {
        padding-left: 16px;
    }

    nav {
        width: 100%;
        display: flex;
        align-items: center;

        @include respond(medium) {
            height: 0;
            overflow: hidden;
            transition: height 0.3s ease-out;
            flex-direction: column;
            align-items: flex-start;
            position: absolute;
            top: 70px;
            left: 0;
            background-color: #fff;
            z-index: 1000;

            &.open {
                height: calc(100vh - 70px - 50px);
                overflow: scroll;
            }

            svg {
                padding-top: 4px;
            }
        }
    }
}

.user_profile_button {
    display: inline-block;
    justify-self: flex-end;
    margin-left: auto;
    padding-bottom: 1px;
    @include respond(medium) {
        display: block;
        padding: 17px 16px;
        position: relative;
        margin-left: 0;
    }
}

.topmenubutton {
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: #282a35;
    display: inline-block;
    margin-left: 30px;
    text-decoration: none;

    &:hover {
        color: #282a35;
        text-decoration: none;
    }

    @include respond(medium) {
        display: block;
        padding: 17px 16px;
        position: relative;
        font-family: Source Sans Pro;
        font-style: normal;
        font-weight: normal;
        font-size: 20px;
        line-height: 25px;
        margin-left: 0;
    }


    @media (min-width: 990px) {
        &.greenright {
            position: absolute;
            right: 113px;
            font-weight: 600;
            color: #282a35;
        }
    }
}

.newicon {
    float: "right";
    margin-left: "8px";
}

.buttonbar {
    display: flex;
    align-items: center;
    width: 100%;
    a:hover {
        color: #04aa6d;
        font-weight: regular;
    }
    svg {
        margin-right: 8px;
        vertical-align: baseline;
    }
}

.selected {
    color: #282a35;
    font-weight: 600;
}
