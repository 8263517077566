.pageContainer {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.main {
  flex: 1;
  background-color: #282a35;
}

.footerWrapper {
  width: 100%;
  max-width: 100%;
  background-color: #282a35;
  margin-top: -2px;
}

.footer {
  max-width: 1400px;
  margin: auto;
  width: 100%;
}
