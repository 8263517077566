@import "./../../../Style/media";

$hamburger-padding-x: 18px !default;
$hamburger-padding-y: 18px !default;
$hamburger-layer-width: 30px !default;
$hamburger-layer-height: 2px !default;
$hamburger-layer-spacing: 6px !default;
$hamburger-layer-color: #282a35 !default;
$hamburger-layer-border-radius: 0px !default;
$hamburger-hover-opacity: 0.7 !default;
$hamburger-active-layer-color: $hamburger-layer-color !default;
$hamburger-active-hover-opacity: $hamburger-hover-opacity !default;

@import "node_modules/hamburgers/_sass/hamburgers/hamburgers.scss";

.hamburger {
    display: none;
    @include respond(medium) {
        display: block;
        position: absolute;
        right: 0;
    }
}
