$titleWrapperGap: 10px;

.wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-wrap: nowrap;
  gap: 50px;

  position: relative;
}

.title {
  font-weight: 600;
  font-size: 36px;
  line-height: 45px;

  &.loading {
    margin-left: $titleWrapperGap + 27px;
  }

  &.error:not(.loading) {
    margin-left: $titleWrapperGap + 24px;
  }
}

.title_wrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  gap: $titleWrapperGap;
  align-items: center;
}
