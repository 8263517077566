@mixin fullscreenContent() {
  border-radius: 0px !important;
  width: 100%;
  height: 100%;
}

.w3s-modal-modal {
  z-index: 999999;

  & .modal-variant-primary {
    color: #04aa6d;

    &:hover {
      color: #059862;
    }
  }

  & .modal-variant-destructive {
    color: #d9212c;

    &:hover {
      color: #c5212c;
    }
  }

  & .modal-top-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    & .modal-top-ok-group {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-end;
    }

    & .modal-ok-alternative {
      font-weight: 600;
      font-size: 18px;
      line-height: 22.63px;
      cursor: pointer;

      &:hover {
        text-decoration: underline;
      }

      &.disabled {
        color: #798488;
        text-decoration: none;
        cursor: default;
      }
    }
  }

  .modal-content {
    border-radius: 16px;
    padding: 24px 28px 21px;
    max-height: none !important;
  }

  .modal-header {
    border-bottom: none;
    padding: 0px 1rem;
  }

  .modal-title {
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    color: #282a35;
  }

  .modal-extra-buttons {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 10px;
    align-items: center;
  }

  .modal-full-width {
    width: 100%;
    flex: 1;
  }

  .modal-footer {
    border-top: none;
    padding: 0px;
  }

  & .modal-dialog-scrollable {
    max-height: none !important;
  }
}

@media (max-width: 575.98px) {
  .w3s-modal-modal {
    .modal-dialog {
      @include fullscreenContent();
      margin: 0px;
    }

    .modal-footer {
      padding-bottom: 0px;
    }

    .modal-content {
      @include fullscreenContent();
    }

    .modal-cancel-button,
    .modal-ok-button {
      display: none;
    }

    .modal-extra-buttons {
      width: 100%;
      & > a,
      & > button {
        width: 100%;
      }
    }
  }
}

@media (max-width: 767.98px) {
  .w3s-modal-modal.w3s-modal-fullscreen-md {
    .modal-dialog {
      margin: 0px;
      max-width: 100vw;
      // max-height: 100vh ;
      width: 100vw;
      height: 100%;
    }

    .modal-content {
      @include fullscreenContent();
      // max-height: 100vh;
    }

    .modal-footer {
      padding-bottom: 0px;
    }

    .modal-cancel-button,
    .modal-ok-button {
      display: none;
    }

    .modal-extra-buttons {
      & > a,
      & > button {
        width: 100%;
      }
    }
  }
}
