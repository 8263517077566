@import "./../../Style/base";
@import "./../../Style/modal";

.forgot_pwd_wrapper {
  width: 100%;
  text-align: center;
  margin-top: 18px;

  a,
  button {
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    text-align: center;
    color: #282a35;
    text-decoration: none;

    &:hover {
      color: #04aa6d;
    }

    &.disabled {
      color: rgba(40, 42, 53, 0.3);
      cursor: unset;
    }
  }
}

.cta_bottom_box_button_login {
  margin-top: 10px;
}

$blue: rgb(24, 119, 242);
$blue-hover: rgb(17, 89, 185);

$grey-light: #dee2e6;
$grey-medium: #c2c8d0;

$grey-dark: #2d333a;

.cta_bottom_box_button_facebook_login {
  margin-top: 10px;
  width: 100%;
  height: 50px;
  background: $blue !important;
  border-color: $blue !important;
  fill: white !important;
  color: white !important;

  &:hover:not(:disabled):not(.loading) {
    background: $blue-hover !important;
    border-color: $blue-hover !important;
  }

  path {
    fill: white !important;
  }
}

.cta_bottom_box_button_google_login {
  margin-top: 10px;
  width: 100%;
  height: 50px;
  background: white !important;
  border-color: $grey-medium !important;
  fill: white !important;
  color: white !important;

  &:hover:not(:disabled):not(.loading) {
    background: $grey-light !important;
    border-color: $grey-medium !important;
  }

  path:nth-of-type(1) {
    fill: #FBBC05 !important;
  }

  path:nth-of-type(2) {
    fill: #EA4335 !important;
  }

  path:nth-of-type(3) {
    fill: #34A853 !important;
  }

  path:nth-of-type(4) {
    fill: #4285F4 !important;
  }
}

.cta_bottom_box_button_google_login_text {
  color: $grey-dark !important;
}

.cta_bottom_box_button_github_login {
  margin-top: 10px;
  width: 100%;
  height: 50px;
  background: white !important;
  border-color: $grey-medium !important;
  fill: white !important;
  color: white !important;

  &:hover:not(:disabled):not(.loading) {
    background: $grey-light !important;
    border-color: $grey-medium !important;
  }

  path {
    fill: black !important;
  }
}

.cta_bottom_box_button_github_login_text {
  color: $grey-dark !important;
}


.cta_bottom_box_button_feide_login {
  margin-top: 10px;
  width: 100%;
  height: 50px;
  background: white !important;
  border-color: $grey-medium !important;
  fill: white !important;
  color: white !important;

  &:hover:not(:disabled):not(.loading) {
    background: $grey-light !important;
    border-color: $grey-medium !important;
  }

  .feide_icon {
    :global {
      .cls-1 {
        fill: #1f4698 !important;
      }
    }
  }
}

$cta_bottom_box_divider-height: 50px;
$cta_bottom_box_divider-left-right-margin: 5px;

.cta_bottom_box_divider {
  margin-top: 10px;
  margin-bottom: 10px;
  width: 100%;
  height: $cta_bottom_box_divider-height;
  line-height: $cta_bottom_box_divider-height;
  border: none;
}

.cta_bottom_box_divider_text {
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 500;
  text-align: center;
  height: inherit;
  display: flex;

  &::before {
    content: "";
    border-bottom: 1px solid #c2c8d0;
    flex: 1 0 auto;
    height: 50%;
    margin-right: $cta_bottom_box_divider-left-right-margin;
  }

  &::after {
    content: "";
    border-bottom: 1px solid #c2c8d0;
    flex: 1 0 auto;
    height: 50%;
    margin-left: $cta_bottom_box_divider-left-right-margin;
  }
}

.pending_verification {
  background-size: 100%;
  background-position: left top;
  background-repeat: no-repeat;
}

.get_access_header {
  font-size: 26px !important;
}

.pending_inner {
  padding-top: 160px;
  padding-bottom: 30px;
}

.instructions {
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 19px;
  color: #6c757d;
}

.modal {
  z-index: 160;

  @include respond(small) {
    top: 6%;
  }

  top: calc(50% + min(73px, max(0px, 918px + 73px - 100%)));
}

.footer {
  bottom: calc(0px - max(0px, 918px + 73px - 100%));
}